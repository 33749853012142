//Source: https://www.taniarascia.com/websockets-in-redux/

import {
	airMeshAircraftTypesSelector,
	airMeshChannelsSelector,
	airMeshProviderTypesSelector,
	airMeshPusherInstanceSelector,
	pauseTrafficUpdatesSelector,
	airMeshSignalSourceTypesSelector,
	resetAMState,
	setAirMeshAircraftTypes,
	setAirMeshProviderTypes,
	setAMConnectionError,
	setAMConnectionLoading,
	setAMPusherInstance,
	setAMTrafficFeatures,
	setAMWebSocketConnected,
	setAirMeshSignalSourceTypes
} from 'reducers/liveTrafficSlice';
import dayjs from 'utils/customDayJS';
import {
	formatAirmeshDataIntoFeatures,
	updatePreviousPositions
} from 'components/airSpace/airspaceUtils';
import Pusher from 'pusher-js';
import {
	AIRMESH_LIVE_TELEMETRY_PUSHER_APP_CLUSTER,
	AIRMESH_LIVE_TELEMETRY_PUSHER_APP_KEY,
	BASE_JWT_URL
} from 'constants/environmentVariables';
import { TOKEN } from 'constants/localStorageConstants';

export const airMeshMiddleware = () => parameters => next => action => {
	const { type } = action;
	const { dispatch, getState } = parameters;
	const airMeshPusherInstance = airMeshPusherInstanceSelector(getState());
	const airMeshChannels = airMeshChannelsSelector(getState());

	const token = localStorage.getItem(TOKEN);

	let inactivityTimeout = null;

	const resetInactivityTimer = dispatch => {
		// Clear any existing timeout
		if (inactivityTimeout) {
			clearTimeout(inactivityTimeout);
		}

		// Set a new 30-second timeout
		inactivityTimeout = setTimeout(() => {
			//Clear all features if there has been no update on any channel for 30 seconds
			//Handles the case where no events are received to trigger the regular pruning of old features
			dispatch(setAMTrafficFeatures([]));
		}, 30000); // 30 seconds
	};

	const handleWebsocketError = () => {
		dispatch(setAMConnectionLoading(false));
		dispatch(setAMWebSocketConnected(false));
		dispatch(setAMConnectionError(true));
		console.error('Failed to connect to Air Mesh');
	};

	const handleWebsocketClosed = () => {
		dispatch(resetAMState());
	};

	const setAircraftTypes = updatedFeatures => {
		const types = updatedFeatures.map(feature => feature.aircraft_type);
		const existingTypes = airMeshAircraftTypesSelector(getState());
		const uniqueTypes = [...new Set([...types, ...existingTypes])];
		dispatch(setAirMeshAircraftTypes(uniqueTypes));
	};

	const setProviderTypes = updatedFeatures => {
		const types = updatedFeatures.map(feature => feature.provider);
		const existingTypes = airMeshProviderTypesSelector(getState());
		const uniqueTypes = [...new Set([...types, ...existingTypes])];
		dispatch(setAirMeshProviderTypes(uniqueTypes));
	};

	const setSignalSourceTypes = updatedFeatures => {
		const types = updatedFeatures.map(feature => feature.signal_source).filter(s => s !== null);
		const existingTypes = airMeshSignalSourceTypesSelector(getState());
		const uniqueTypes = [...new Set([...types, ...existingTypes])];
		dispatch(setAirMeshSignalSourceTypes(uniqueTypes));
	};

	const handleApplyUpdates = event => {
		resetInactivityTimer(dispatch);
		const pauseTrafficUpdates = pauseTrafficUpdatesSelector(getState());
		if (!pauseTrafficUpdates) {
			const updatedFeatures = updateFeatures(event.data, getState().liveTraffic.airMeshFeatures);
			dispatch(setAMTrafficFeatures(updatedFeatures));
			setAircraftTypes(updatedFeatures);
			setProviderTypes(updatedFeatures);
			setSignalSourceTypes(updatedFeatures);
		}
	};

	switch (type) {
		case 'airMesh/connect': {
			const newPusherInstance = new Pusher(AIRMESH_LIVE_TELEMETRY_PUSHER_APP_KEY, {
				cluster: AIRMESH_LIVE_TELEMETRY_PUSHER_APP_CLUSTER,
				channelAuthorization: {
					endpoint: `${BASE_JWT_URL}/api/pusher-auth`,
					headers: {
						Authorization: 'Bearer ' + token
					}
				}
			});

			dispatch(setAMPusherInstance(newPusherInstance));

			newPusherInstance.connection.bind('connected', () => {
				dispatch(setAMWebSocketConnected(true));
				dispatch(setAMConnectionError(false));
				dispatch(setAMConnectionLoading(false));
				dispatch(airMeshHandleSubscriptions());
			});

			newPusherInstance.connection.bind('failed', e => {
				handleWebsocketError();
			});

			newPusherInstance.connection.bind('unavailable', () => {
				handleWebsocketError();
			});

			newPusherInstance.connection.bind('disconnected', () => {
				handleWebsocketClosed();
			});
			break;
		}
		case 'airMesh/handleSubscriptions': {
			const pusherKnownChannels = airMeshPusherInstance?.channels?.channels
				? Object.values(airMeshPusherInstance?.channels?.channels)
				: [];
			const pusherSubscribedChannels = pusherKnownChannels.filter(c => c.subscribed);

			const addedChannelNames = airMeshChannels.filter(
				channel => !pusherSubscribedChannels.map(c => c.name).includes(channel)
			);
			const removedChannels = pusherSubscribedChannels.filter(
				channel => !airMeshChannels.includes(channel.name)
			);

			addedChannelNames.forEach(channelName => {
				const newChannel = airMeshPusherInstance.subscribe(channelName);
				//Listen for track-event data
				newChannel.bind('track-event', event => {
					handleApplyUpdates(event);
				});

				//Listen for events to handle errors
				newChannel.bind('pusher:subscription_error', e => {
					handleWebsocketError(e.error);
				});
			});

			removedChannels.forEach(channel => {
				channel.unsubscribe();
			});

			break;
		}
		case 'airMesh/disconnect': {
			if (airMeshPusherInstance) {
				airMeshPusherInstance.disconnect();
			}
			break;
		}
		default: {
			break;
		}
	}

	return next(action);
};

export const airMeshConnect = payload => ({ type: 'airMesh/connect', payload });
export const airMeshDisconnect = () => ({ type: 'airMesh/disconnect' });
export const airMeshHandleSubscriptions = () => ({ type: 'airMesh/handleSubscriptions' });

const hideAsterixThresholdSeconds = 4.7;
const expireAsterixThresholdMinutes = 10;
const expireRegularThresholdSeconds = 30;

// Add a Map to store hidden features
let hiddenFeaturesMap = new Map();

const getFeatureState = (feature, now) => {
	const featureDate = dayjs(feature.properties.timestamp);
	const isAsterix = feature.properties.aircraft_type === 'asterix';
	const timeSinceUpdate = now.diff(featureDate, 'seconds');

	// Check expiration first
	const isExpired =
		featureDate <=
		(isAsterix
			? now.subtract(expireAsterixThresholdMinutes, 'minutes')
			: now.subtract(expireRegularThresholdSeconds, 'seconds'));

	if (isExpired) return 'EXPIRED';
	if (!isAsterix) return 'VISIBLE';
	return timeSinceUpdate > hideAsterixThresholdSeconds ? 'HIDDEN' : 'VISIBLE';
};

const updateFeatureWithPrevious = (newFeature, previousFeature) => {
	const altitudeChange = newFeature.properties?.altitude
		? newFeature.properties.altitude - previousFeature.properties.altitude
		: 0;

	const previousPositions = updatePreviousPositions(previousFeature, newFeature);

	return {
		...newFeature,
		altitude_change: altitudeChange,
		properties: {
			...newFeature.properties,
			altitude_change: altitudeChange,
			previous_positions: previousPositions
		}
	};
};

export const updateFeatures = (incomingFeatures, currentFeatures) => {
	const now = dayjs();
	const processedFeatures = new Map();

	// Create maps for faster lookups
	const incomingFeaturesMap = new Map(
		formatAirmeshDataIntoFeatures(incomingFeatures).map(feature => [feature.id, feature])
	);

	const currentFeaturesMap = new Map(
		currentFeatures
			.filter(feature => getFeatureState(feature, now) !== 'EXPIRED')
			.map(feature => [feature.id, feature])
	);

	// Clean up expired features from hiddenFeaturesMap
	for (const [id, feature] of hiddenFeaturesMap) {
		if (getFeatureState(feature, now) === 'EXPIRED') {
			hiddenFeaturesMap.delete(id);
		}
	}

	const allFeatureIds = new Set([
		...incomingFeaturesMap.keys(),
		...currentFeaturesMap.keys(),
		...hiddenFeaturesMap.keys()
	]);

	for (const id of allFeatureIds) {
		const incomingFeature = incomingFeaturesMap.get(id);
		const currentFeature = currentFeaturesMap.get(id);
		const hiddenFeature = hiddenFeaturesMap.get(id);

		// Skip if no feature data available
		if (!incomingFeature && !currentFeature && !hiddenFeature) continue;

		// Determine which feature data to use
		let featureToProcess = null;

		if (incomingFeature) {
			// If we have new data, update it with previous data if available
			const previousFeature = currentFeature || hiddenFeature;
			featureToProcess = previousFeature
				? updateFeatureWithPrevious(incomingFeature, previousFeature)
				: incomingFeature;
		} else {
			// No new data, use existing feature
			featureToProcess = currentFeature || hiddenFeature;
		}

		if (!featureToProcess) continue;

		const state = getFeatureState(featureToProcess, now);
		const isAsterix = featureToProcess.properties.aircraft_type === 'asterix';

		if (isAsterix) {
			switch (state) {
				case 'VISIBLE':
					processedFeatures.set(id, featureToProcess);
					hiddenFeaturesMap.delete(id);
					break;
				case 'HIDDEN':
					hiddenFeaturesMap.set(id, featureToProcess);
					// Don't add to processedFeatures when hidden
					break;
				case 'EXPIRED':
					// Do nothing - let it be filtered out
					break;
				default:
					break;
			}
		} else {
			// Non-asterix features are always visible unless expired
			if (state !== 'EXPIRED') {
				processedFeatures.set(id, featureToProcess);
			}
		}
	}

	return [...processedFeatures.values()];
};
