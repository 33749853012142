import { createSlice } from '@reduxjs/toolkit';
import { initialStoreState } from 'services/configureStore';

const initialState = {
	//Set by configureStore
};

const userAuthSlice = createSlice({
	name: 'userAuth',
	initialState,
	reducers: {
		setToken(state, action) {
			state.token = action.payload;
		},
		setUser(state, action) {
			return { ...state, user: { ...action.payload } };
		},
		setAccountData(state, action) {
			state.accountData = action.payload;
		},
		setAccountsLoading(state, action) {
			state.accountsLoading = action.payload;
		},
		setAccounts(state, action) {
			// add new accounts to state (for pagination)
			const updatedAccounts = state?.accounts
				? [...state.accounts, ...action.payload]
				: action.payload;
			// filter and duplicates in accounts array
			const dedupedAccounts = updatedAccounts.filter(
				(value, index, self) => index === self.findIndex(t => t.id === value.id)
			);
			state.accounts = dedupedAccounts;
		},
		setAccountsPagination(state, action) {
			state.accountsPagination = action.payload;
		},
		setCurrentAccountId(state, action) {
			state.currentAccountId = action.payload;
		},
		setUserRole(state, action) {
			return {
				...state,
				user: {
					...state.user,
					role: action.payload
				}
			};
		},
		setAmplifyError(state, action) {
			state.amplifyError = action.payload;
		},
		setUserDetailsLoading(state, action) {
			state.userDetailsLoading = action.payload;
		},
		setUserDetailsLoaded(state, action) {
			state.userDetailsLoaded = action.payload;
		},
		setAccountDataLoading(state, action) {
			state.accountDataLoading = action.payload;
		},
		setLogoutErrorFound(state, action) {
			state.logoutErrorFound = action.payload;
		},
		setAccountExpiringDialogOpen(state, action) {
			state.accountExpiringDialogOpen = action.payload;
		},
		resetUserAuthSlice() {
			return initialStoreState.userAuth;
		},
		setRequestingResetCode(state, action) {
			state.requestingResetCode = action.payload;
		},
		setResetCodeSent(state, action) {
			state.resetCodeSent = action.payload;
		}
	}
});

export const {
	setToken,
	setUser,
	setAccountData,
	setAccountsLoading,
	setAccounts,
	setAccountsPagination,
	setCurrentAccountId,
	setUserRole,
	setAmplifyError,
	setAmplifyAlert,
	setUserDetailsLoading,
	setUserDetailsLoaded,
	setAccountDataLoading,
	setLogoutErrorFound,
	setAccountExpiringDialogOpen,
	resetUserAuthSlice,
	setRequestingResetCode,
	setResetCodeSent
} = userAuthSlice.actions;

export default userAuthSlice.reducer;

export const tokenSelector = state => state.userAuth.token;
export const refreshTokenSelector = state => state.userAuth.refreshToken;
export const currentUserSelector = state => state.userAuth.user;
export const featuresSelector = state => state.userAuth.accountData?.features;
export const settingsSelector = state => state.userAuth.accountData?.settings;
export const accountDataSelector = state => state.userAuth.accountData;
export const userRoleSelector = state => state.userAuth.user?.role;
export const currentUserIdSelector = state => state.userAuth.user?.id;
export const currentAccountIdSelector = state => state.userAuth.currentAccountId;
export const currentAccountSelector = state =>
	state.userAuth.accounts.find(account => account.id === state.userAuth.currentAccountId);
export const amplifyErrorSelector = state => state.userAuth.amplifyError;
export const accountsLoadingSelector = state => state.userAuth.accountsLoading;
export const userAccountsSelector = state => state.userAuth.accounts;
export const accountsPaginationSelector = state => state.userAuth.accountsPagination;
export const resetPasswordEmailSelector = state => state.userAuth.resetPasswordEmail;
export const subscriptionTypeSelector = state => state.userAuth?.accountData?.subscription_type;
export const userDetailsLoadingSelector = state => state.userAuth.userDetailsLoading;
export const userDetailsLoadedSelector = state => state.userAuth.userDetailsLoaded;
export const accountDataLoadingSelector = state => state.userAuth.accountDataLoading;
export const logoutErrorFoundSelector = state => state.userAuth.logoutErrorFound;
export const accountExpiringDialogOpenSelector = state => state.userAuth.accountExpiringDialogOpen;
export const accountRegionSelector = state => state.userAuth?.accountData?.region;
export const requestingResetCodeSelector = state => state.userAuth?.requestingResetCode;
export const resetCodeSentSelector = state => state.userAuth?.resetCodeSent;

/**
 * current user configured locale
 * @returns {string|undefined}
 */
export const userLocaleSelector = state => state.userAuth?.user?.locale || undefined;

/**
 * current account configured locale
 * @returns {string|undefined}
 */
export const accountLocaleSelector = state => state.userAuth?.accountData?.locale || undefined;

/**
 * locale that should be used
 * 	user. locale with fall back to
 * 	current selected account.local
 * @returns {string|undefined}
 */
export const localeSelector = state => userLocaleSelector(state) ?? accountLocaleSelector(state);
export const isAdminSelector = state => state.userAuth?.user?.role?.is_admin;
export const userLicensesSelector = state => state.userAuth?.accountData?.user_licenses;
export const userCountSelector = state => state.userAuth?.accountData?.user_count;
export const accountSettingsSelector = state => state.userAuth?.accountData?.settings;
